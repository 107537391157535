import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import { DEFAULT_CREDITS, PLAN_TYPE_ANNUALLY_INSTALLMENT, PLAN_TYPES } from 'config/products';
import { PLUS_PLANS, AFTERPAY_ACCEPTED_COUNTRIES, CLEARPAY_ACCEPTED_COUNTRIES } from 'config/payments';
import decodeHtmlEntity from 'helpers/client_side/decodeHtmlEntity';

const PlanPricing = (props) => {
    const {
        CommonDetails,
        cardType,
        showCustomCredits,
        credits,
        customCreditsDetails,
        data,
        isYearlyPlan,
        autorenew,
        subscriptionMonthlyPlan,
    } = props;
    const {
        countryOrigin, 
        promoCode,
        lang
    } = CommonDetails;
    const isCustomCredits = showCustomCredits && credits === DEFAULT_CREDITS.defaultCustom;
    const creditsData = isCustomCredits ? customCreditsDetails?.credits || data : data;
    const price = creditsData?.price?.formatted_price || '';
    const promoNEW50Price = creditsData?.promo_price?.formatted_price || '';
    const bundlePrice = creditsData?.bundle_price?.formatted_price || '';
    const promoNEW50BundlePrice = creditsData?.promo_bundle_price?.formatted_price || '';
    const isInstallmentPlan = PLAN_TYPE_ANNUALLY_INSTALLMENT.includes(data?.plan);
    const isWeeklyPLUSPlan = data?.plan === PLUS_PLANS.plusWeekly;
    const isPLUSFreeTrialPlan = data?.plan === 'fplus7';
    const isYearlyPlusPlan = data?.plan === 'splus12';
    const isYearlyPlusBusiness = data?.plan === 'plusb12';
    const isPromoNEW50 = promoCode === 'NEW50';
    const showYearlyText = subscriptionMonthlyPlan && (countryOrigin === 'RU' || countryOrigin === 'KR');
    const isAfterPaySupportedCountry = AFTERPAY_ACCEPTED_COUNTRIES.includes(countryOrigin);
    const isClearPaySupportedCountry = CLEARPAY_ACCEPTED_COUNTRIES.includes(countryOrigin);
    const previousPrice = data?.usave?.previous_price_rounded;
    const currentPrice = (isYearlyPlusPlan || isYearlyPlusBusiness) ? data?.price_per_month?.rounded_price : data?.price?.rounded_price;
    const showPriceSlashed = !isYearlyPlusBusiness && previousPrice > currentPrice;
    const unitText = showYearlyText ? 'SHARED.Year' : isWeeklyPLUSPlan ? 'SHARED.Week' : 'SHARED.Month';
    const language = lang.toLowerCase();
    const LONG_PRICING_LANGUAGE = ['de', 'fr', 'it', 'nl', 'pl', 'pt', 'ru'];
    const longPricingText = LONG_PRICING_LANGUAGE.includes(language) || isPromoNEW50 || showPriceSlashed;

    const displayPrice = () => {
        if (cardType === PLAN_TYPES.SUBSCRIPTION) {
            return (
                <>
                    {isPromoNEW50 && (
                        <>
                            <span className="PlanPricing__priceSlashed">
                                {decodeHtmlEntity(
                                    isInstallmentPlan
                                        ? isYearlyPlan
                                            ? data?.price_installment?.formatted_price
                                            : data?.price?.formatted_price
                                        : data?.price?.formatted_price,
                                )}
                            </span>
                            {decodeHtmlEntity(
                                isInstallmentPlan
                                    ? isYearlyPlan
                                        ? data?.promo_price_per_month?.formatted_price
                                        : data?.promo_price?.formatted_price
                                    : data?.promo_price?.formatted_price,
                            )}
                            <span className="PlanPricing__perUnit">
                                /{intl.get(unitText)}
                            </span>
                        </>
                    )}
                    {!isPromoNEW50 && (
                        <>
                            {decodeHtmlEntity(
                                isYearlyPlan && (isAfterPaySupportedCountry || isClearPaySupportedCountry)
                                ? data?.price_per_month?.formatted_price
                                : isInstallmentPlan
                                    ? isYearlyPlan
                                        ? data?.price_installment?.formatted_price
                                        : data?.price?.formatted_price
                                    : data?.price?.formatted_price,
                            )}
                            <span className="PlanPricing__perUnit">
                                /{intl.get(unitText)}
                            </span>
                        </>
                    )}
                </>
            );
        }

        if (cardType === PLAN_TYPES.PLUS || cardType === PLAN_TYPES.PLUS_BUSINESS) {
            return (
                <>
                    {isPromoNEW50 && !isPLUSFreeTrialPlan && (
                        <>
                            <span className="PlanPricing__priceSlashed">
                                {decodeHtmlEntity(
                                    isYearlyPlusPlan || isYearlyPlusBusiness
                                        ? data?.price_per_month?.formatted_price
                                        : data?.price?.formatted_price
                                )}
                            </span>
                            {decodeHtmlEntity(
                                isYearlyPlusPlan || isYearlyPlusBusiness
                                    ? data?.promo_price_per_month?.formatted_price
                                    : data?.promo_price?.formatted_price
                            )}
                        </>
                    )}
                    {
                    !isPromoNEW50 || isPLUSFreeTrialPlan 
                    ? (
                        <>
                            {!isPLUSFreeTrialPlan && showPriceSlashed && (
                                <span className="PlanPricing__priceSlashed">
                                    {decodeHtmlEntity(data?.usave?.previous_price_formatted)}
                                </span>
                            )}
                            {decodeHtmlEntity(
                                isYearlyPlusPlan || isYearlyPlusBusiness
                                    ? data?.price_per_month?.formatted_price
                                    : data?.price?.formatted_price
                            )}
                        </>
                    ) : null}
                    {!isPLUSFreeTrialPlan && (
                        <span className="PlanPricing__perUnit">
                            /{intl.get(unitText)}
                        </span>
                    )}
                    {isPLUSFreeTrialPlan && (
                        <>
                            <span className="PlanPricing__subText">
                                {intl.get('PRODUCTS.CancelAnytime')}
                            </span>
                        </>
                    )}
                </>
            );
        }

        return (
            <>
                {isPromoNEW50 ? (
                    <>
                        <span className="PlanPricing__priceSlashed">{decodeHtmlEntity(autorenew ? bundlePrice : price)}</span>
                        {decodeHtmlEntity(autorenew ? promoNEW50BundlePrice : promoNEW50Price)}
                    </>
                ) : (
                    decodeHtmlEntity(autorenew ? bundlePrice : price)
                )}
            </>
        );
    };

    if (!showCustomCredits && credits === DEFAULT_CREDITS.defaultCustom) return null;

    return (
        <div className="PlanPricing__priceWrapper">
            <span className="PlanPricing__price">{displayPrice()}</span>

            <style jsx global>
                {`
                    .PlanPricing {
                        &__priceWrapper {
                            width: auto;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            text-align: right;
                        }

                        &__price {
                            display: inline-block;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: right;
                            color: #212121;
                        }

                        &__priceSlashed {
                            display: ${ longPricingText ? 'block' : 'unset'};
                            color: #e91d36;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1.125rem;
                            text-decoration-line: line-through;
                            margin-right: 4px;
                        }

                        &__perUnit {
                            display: ${ longPricingText  ? 'block' : 'unset'};
                            color: #212121;
                            font-size: ${ longPricingText ? '12px' : '16px'};
                            font-style: normal;
                            font-weight: 400;
                            line-height: ${ longPricingText ? '18px' : '24px'};
                            margin-left: 4px;
                        }

                        &__subText {
                            display: block;
                            color: #a3a3a3;
                            text-align: right;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            white-space: initial;
                        }
                    }
                `}
            </style>
        </div>
    );
};

PlanPricing.propTypes = {
    CommonDetails: PropTypes.objectOf(PropTypes.shape),
    cardType: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.shape).isRequired,
    showCustomCredits: PropTypes.bool,
    customCreditsDetails: PropTypes.objectOf(PropTypes.shape),
    credits: PropTypes.number.isRequired,
    isYearlyPlan: PropTypes.bool.isRequired,
    autorenew: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    subscriptionMonthlyPlan: PropTypes.bool,
};

PlanPricing.defaultProps = {
    CommonDetails: {
        countryOrigin: 'US',
    },
    showCustomCredits: false,
    customCreditsDetails: null,
    autorenew: false,
    subscriptionMonthlyPlan: false,
};

export default PlanPricing;
