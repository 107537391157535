import { useContext } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

// Pre-defined Components
import {
    SUBS_PLAN_NAMES_ALL,
    COUNTRIES_WITHOUT_INSTALLMENT,
    CREDITS_LIST,
    SUBS_PLAN_NAMES,
    SUBS_PLAN_NAMES_REGULAR,
} from 'config/products';
import {
    AFTERPAY_ACCEPTED_COUNTRIES,
    CLEARPAY_ACCEPTED_COUNTRIES 
} from 'config/payments';
import { PAGE_FOLDERS as pageFolders } from 'config/constants';
import checkIsServer from 'helpers/server_side/checkIsServer';
import redirectToInitLangSite from 'helpers/server_side/redirectToInitLangSite';
import redirectToLangSiteByCountry from 'helpers/server_side/redirectToLangSiteByCountry';
import redirectToSiteWithSubdomain from 'helpers/server_side/redirectToSiteWithSubdomain';

// Context Components
import { CommonDetailsContext } from 'contexts/CommonDetails';
import { ProductDetailsProvider } from 'contexts/ProductDetails';

// Products Styles
import productsStyles from 'styles/products.global';

// APIs
import getAllProducts from 'api/queries/getAllProducts';

// Main Component
import ProductsMainPage from 'components/Main/ProductsMainPage';

const UpgradeBrowser = dynamic(() => import('components/Shared/UpgradeBrowser'), { ssr: false });

function ProductsIndex(props) {
    const { isServer, products } = props;
    const CommonDetails = useContext(CommonDetailsContext);
    const { browser } = CommonDetails;

    return (
        <>
            <ProductDetailsProvider products={products} CommonDetails={CommonDetails}>
                {isServer && browser.isIE && <UpgradeBrowser />}
                <ProductsMainPage />
            </ProductDetailsProvider>

            <style jsx global>
                {productsStyles}
            </style>
        </>
    );
}

ProductsIndex.getInitialProps = async function ({
    ctx,
    cookies,
    countryOrigin: countryCode,
    hostName,
    locale,
}) {
    const isServer = checkIsServer(ctx);

    // Perform params checks and redirects
    if (redirectToSiteWithSubdomain(ctx)) return {};
    if (redirectToInitLangSite({ ctx, cookies, hostName })) return {};
    if (redirectToLangSiteByCountry({ ctx, locale, cookies, hostName })) return {};

    const products = countryCode
        ? await getAllProducts({
              ctx,
              countryCode,
              subsPlanNames: COUNTRIES_WITHOUT_INSTALLMENT.includes(countryCode)
                  ? SUBS_PLAN_NAMES_REGULAR
                : AFTERPAY_ACCEPTED_COUNTRIES.includes(countryCode) || CLEARPAY_ACCEPTED_COUNTRIES.includes(countryCode)
                    ? SUBS_PLAN_NAMES_ALL
                    : SUBS_PLAN_NAMES,
              aioPlanNames: '',
              creditsList: CREDITS_LIST,
              slim: 0,
          })
        : null;

    return { isServer, products, pagePath: pageFolders.products };
};

ProductsIndex.propTypes = {
    products: PropTypes.objectOf(PropTypes.shape),
    isServer: PropTypes.bool,
};

ProductsIndex.defaultProps = {
    products: null,
    isServer: false,
};

export default ProductsIndex;
