/**
 * calculate SHA256 hash
 * @param {Array} data 
 * @returns {String}
 */
const calculateHash = async (data)  => {
    // Convert data to JSON string
    const jsonData = JSON.stringify(data);

    // Calculate SHA256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(jsonData));
    
    // Convert hash buffer to hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    // console.log(hashHex);
    return hashHex;
}

export default calculateHash;