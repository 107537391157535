import React, { createContext, useContext, useState, useRef, useEffect , useMemo, startTransition } from 'react';
import PropTypes from 'prop-types';

const HeightContext = createContext();

export const HeightProvider = ({ children }) => {
  const [plusPlanHeight, setPlusPlanHeight] = useState('215px');
  const [subsPlanHeight, setSubsPlanHeight] = useState('370px');
  const plusPlanRef = useRef(null);
  const subsPlanRef = useRef(null);

  const updateHeight = (ref, setFunction) => {
    if (ref.current) {
      const heightWithoutMargin = ref.current.offsetHeight;

      startTransition(() => {
        setFunction(`${heightWithoutMargin}px`);
      });
    }
  };

  useEffect(() => {
    updateHeight(plusPlanRef, setPlusPlanHeight);
    updateHeight(subsPlanRef, setSubsPlanHeight);

    const observer1 = new ResizeObserver(() => updateHeight(plusPlanRef, setPlusPlanHeight));
    const observer2 = new ResizeObserver(() => updateHeight(subsPlanRef, setSubsPlanHeight));

    if (plusPlanRef.current) observer1.observe(plusPlanRef.current);
    if (subsPlanRef.current) observer2.observe(subsPlanRef.current);

    // Cleanup the observers on unmount
    return () => {
      if (plusPlanRef.current) observer1.unobserve(plusPlanRef.current);
      if (subsPlanRef.current) observer2.unobserve(subsPlanRef.current);
    };
  }, []);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(() => ({
    plusPlanHeight,
    subsPlanHeight,
    plusPlanRef,
    subsPlanRef,
  }), [plusPlanHeight, subsPlanHeight]);

  return (
    <HeightContext.Provider value={contextValue}>
      {children}
    </HeightContext.Provider>
  );
};

export const useHeightContext = () => {
  return useContext(HeightContext);
};

HeightProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
