import { useMemo, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { useHeightContext } from 'contexts/PricingPlanHeight';

// Pre-defined Components
import {
    COUNTRIES_WITHOUT_INSTALLMENT,
    PLAN_DURATION,
    PLAN_TYPE_ANNUALLY,
    PLAN_TYPE_ANNUALLY_INSTALLMENT,
    PLAN_TYPE_MONTHLY,
    PLAN_TYPES,
} from 'config/products';
import { PAGE_FOLDERS, DEFAULT_STATE_TEXT_VALUE as stateDefault } from 'config/constants';
import { PAYMENT_METHOD, AFTERPAY_ACCEPTED_COUNTRIES, CLEARPAY_ACCEPTED_COUNTRIES } from 'config/payments';

// Sub Components
import PlanOptionItem from 'components/Products/NewAffordablePlans/Shared/PlanOptionItem/index';
import CustomSwitchHorizontal from 'components/Shared/CustomSwitchHorizontal';
import SubscriptionPlanDetails from './SubscriptionPlanDetails';

const SubscriptionPlan = (props) => {
    const { isPricingCardButtonClicked, products, CommonDetails, GTM, WindowDimension } = props;

    const { subsPlanRef } = useHeightContext();
    const { locale, countryOrigin: co, promoCode, utmCampaign } = CommonDetails;
    const { isTabletView, isMobileView, isDesktopView } = WindowDimension;
    const countryOrigin = locale?.country?.code || co;
    const isAfterPaySupportedCountry = AFTERPAY_ACCEPTED_COUNTRIES.includes(countryOrigin);
    const isClearPaySupportedCountry = CLEARPAY_ACCEPTED_COUNTRIES.includes(countryOrigin);

    const subscriptionPlanOptions =
        products?.subs?.filter(
            (prod) => 
                (COUNTRIES_WITHOUT_INSTALLMENT.includes(countryOrigin) || isAfterPaySupportedCountry || isClearPaySupportedCountry
                    ? PLAN_TYPE_ANNUALLY.includes(prod?.plan)
                    : PLAN_TYPE_ANNUALLY_INSTALLMENT.includes(prod?.plan)) ||
                PLAN_TYPE_MONTHLY.includes(prod?.plan),
        ) || [];
    const DEFAULT_SELECTED_PLAN = COUNTRIES_WITHOUT_INSTALLMENT.includes(countryOrigin) || isAfterPaySupportedCountry || isClearPaySupportedCountry
        ? PLAN_TYPE_ANNUALLY[1]
        : PLAN_TYPE_ANNUALLY_INSTALLMENT[1];
    
    // Local States
    const [selectedPlan, setSelectedPlan] = useState(DEFAULT_SELECTED_PLAN);
    const [monthlyPlan, setMonthlyPlan] = useState(false);

    const isYearlyPlan =
        PLAN_TYPE_ANNUALLY.includes(selectedPlan) ||
        PLAN_TYPE_ANNUALLY_INSTALLMENT.includes(selectedPlan);

    const handleOptionSelection = (selected) => {
        if (selectedPlan !== selected) {
            setSelectedPlan(selected);

            const planduration =
                selected.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
            const planVariant = `${(selected.indexOf('12') >= 0
                ? selected.substring(0, selected.length - 2)
                : selected.substring(0, selected.length - 1)
            ).replace(/^\w/, (c) => c.toUpperCase())}`;
            const planPrice =
                subscriptionPlanOptions.filter((o) => o?.plan === selected)[0]?.price
                    ?.rounded_price || 0;
            const planType = selected.replace(/\d+/, '');
            const planTypeDesc =
                planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${planTypeDesc} — ${planduration} ${
                                    planduration > 1 ? 'Months' : 'Month'
                                }`,
                                id: selected,
                                price: planPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Subscription',
                                variant: `${planVariant} Subscription`,
                                quantity: 1,
                                metric1: 0,
                                metric2: 0,
                                metric3: planduration,
                            },
                        ],
                    },
                },
            });
        }
    };

    const toggleSubscriptionPlan = () => {
        const newSelectedPlan =
            PLAN_TYPE_ANNUALLY.includes(selectedPlan) ||
            PLAN_TYPE_ANNUALLY_INSTALLMENT.includes(selectedPlan)
                ? `${selectedPlan.replace(/i-/g, '').replace(/12/g, '1')}`
                : COUNTRIES_WITHOUT_INSTALLMENT.includes(countryOrigin) || isAfterPaySupportedCountry || isClearPaySupportedCountry
                ? `${selectedPlan}2`
                : `i-${selectedPlan}2`;
        setMonthlyPlan(!monthlyPlan);
        setSelectedPlan(newSelectedPlan);

        const planduration =
            newSelectedPlan.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
        const planVariant = `${(newSelectedPlan.indexOf('12') >= 0
            ? newSelectedPlan.substring(0, newSelectedPlan.length - 2)
            : newSelectedPlan.substring(0, newSelectedPlan.length - 1)
        ).replace(/^\w/, (c) => c.toUpperCase())}`;
        const planPrice =
            subscriptionPlanOptions.filter((o) => o?.plan === newSelectedPlan)[0]?.price
                ?.rounded_price || 0;
        const planType = newSelectedPlan.replace(/\d+/, '');
        const planTypeDesc = planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

        GTM.pushGTMData({
            event: 'ecommerce_detail',
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: `${planTypeDesc} — ${planduration} ${
                                planduration > 1 ? 'Months' : 'Month'
                            }`,
                            id: newSelectedPlan,
                            price: planPrice,
                            currency: locale.currency.code,
                            brand: 'Online',
                            category: 'Subscription',
                            variant: `${planVariant} Subscription`,
                            quantity: 1,
                            metric1: 0,
                            metric2: 0,
                            metric3: planduration,
                        },
                    ],
                },
            },
        });
    };

    useEffect(() => {
        const goToCheckoutPage = () => {
            const planduration =
                selectedPlan.indexOf('12') >= 0 ? PLAN_DURATION.yearly : PLAN_DURATION.monthly;
            const planVariant = `${(selectedPlan.indexOf('12') >= 0
                ? selectedPlan.substring(0, selectedPlan.length - 2)
                : selectedPlan.substring(0, selectedPlan.length - 1)
            ).replace(/^\w/, (c) => c.toUpperCase())}`;
            const planPrice =
                subscriptionPlanOptions.filter((o) => o?.plan === selectedPlan)[0]?.price
                    ?.rounded_price || 0;
            const planType = selectedPlan.replace(/\d+/, '');
            const planTypeDesc =
                planType === 'basic' ? 'Daily Subscription' : 'Monthly Subscription';

            GTM.pushGTMData({
                event: 'ecommerce_detail',
                ecommerce: {
                    detail: {
                        products: [
                            {
                                name: `${planTypeDesc} — ${planduration} ${
                                    planduration > 1 ? 'Months' : 'Month'
                                }`,
                                id: selectedPlan,
                                price: planPrice,
                                currency: locale.currency.code,
                                brand: 'Online',
                                category: 'Subscription',
                                variant: `${planVariant} Subscription`,
                                quantity: 1,
                                metric1: 0,
                                metric2: 0,
                                metric3: planduration,
                            },
                        ],
                    },
                },
            });

            GTM.pushGTMData({
                event: 'BuyNowSubscriptionPlanClicked',
                category: 'PricingPageCTA',
                label: 'Subscription',
                action: 'Plan Type',
            });

            if (
                countryOrigin === 'KR' &&
                (stateDefault.paymentMethodKR === PAYMENT_METHOD.xpay ||
                    ((isMobileView || isTabletView) && process.env.KGINICIS_MOBILE === 'OFF'))
            ) {
                const checkoutURL = `${window.location.origin}/checkout-v2/xpay/xpay_payment_request.php?pkg=subs&splan=${selectedPlan}`;
                window.location.href = checkoutURL;
            } else {
                const checkoutURL = `/${
                    PAGE_FOLDERS.checkout
                }/?pkg=subs&plan=${selectedPlan}&po=pricing${
                    (utmCampaign && utmCampaign === "2024SpringPromo")
                        ? `&promocode=SPRING30`
                        : promoCode
                            ? `&promocode=${promoCode}`
                            : ``
                }`;
                Router.push(checkoutURL).then(() => window.scrollTo(0, 0));
            }
        };

        if (isPricingCardButtonClicked !== undefined) {
            goToCheckoutPage();
        }
    }, [isPricingCardButtonClicked]);

    const CustomSwitchMemo = useMemo(
        () => (
            <CustomSwitchHorizontal
                on={!monthlyPlan}
                onText={intl.getHTML(
                    COUNTRIES_WITHOUT_INSTALLMENT.includes(countryOrigin) || isAfterPaySupportedCountry || isClearPaySupportedCountry
                        ? 'PRODUCTS.AnnualPlan'
                        : 'PRODUCTS.AnnualPlanPaidMonthlyMob',
                )}
                offText={intl.get(
                    COUNTRIES_WITHOUT_INSTALLMENT.includes(countryOrigin) || isAfterPaySupportedCountry || isClearPaySupportedCountry
                        ? 'PRODUCTS.MonthlyPlan'
                        : 'PRODUCTS.MonthToMonth',
                )}
                onSwitchChange={toggleSubscriptionPlan}
                WindowDimension={WindowDimension}
            />
        ),
        [monthlyPlan, toggleSubscriptionPlan, countryOrigin],
    );

    return (
        <section className="SubscriptionPlan__wrapper">
            <div className="SubscriptionPlan__subWrapper" ref={subsPlanRef}>
                <div className="SubscriptionPlan__switchWrapper">{CustomSwitchMemo}</div>

                {subscriptionPlanOptions.map((spo) => {
                    if (
                        (monthlyPlan && spo?.duration !== 1) ||
                        (!monthlyPlan && spo?.duration !== 12)
                    ) { 
                        return null;
                    }
                    
                    return (
                        <PlanOptionItem
                            key={spo?.plan}
                            cardType={PLAN_TYPES.SUBSCRIPTION}
                            data={spo}
                            selectedPlan={selectedPlan}
                            handleOptionSelection={handleOptionSelection}
                            subscriptionMonthlyPlan={!monthlyPlan}
                            {...props}
                            {...CommonDetails}
                            {...WindowDimension}
                        />
                    );
                })}
            </div>
            <SubscriptionPlanDetails isYearlyPlan={isYearlyPlan} {...props} />
            <style jsx>
                {`
                    .SubscriptionPlan {
                        &__wrapper {
                            width: 100%;
                        }

                        &__switchWrapper {
                            margin-bottom: 8px;
                        }

                        &__subWrapper {
                            min-height: ${isDesktopView
                                ? '390px' :
                                isTabletView
                                ? '370px'
                                : 'auto'};
                            display: flex;
                            flex-direction: column;
                            row-gap: 8px;
                            column-gap: 11px;
                            margin: 0 0 16px 0;
                        }
                    }
                `}
            </style>
        </section>
    );
};

SubscriptionPlan.propTypes = {
    isPricingCardButtonClicked: PropTypes.bool,
    products: PropTypes.objectOf(PropTypes.shape),
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    GTM: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
};

SubscriptionPlan.defaultProps = {
    isPricingCardButtonClicked: undefined,
    products: null,
};

export default SubscriptionPlan;
